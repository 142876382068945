import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'chatStateBadget',
    standalone: true
})
export class ChatStateBadgetPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch(value){
      case true: return 'Pendiente'; 
      case false: return 'Leído';
      default: return value
    }
  }

}
