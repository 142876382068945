import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gridtag',
    standalone: true
})
export class GridTagPipe implements PipeTransform {

  transform(values, position: number, ...args: unknown[]): unknown {
    if(values){
      return `${this.genString(values, position)}`;
    } else {
      return null;
    }
  }

  genString(values, position: number): String {
    let etapa: string = '';
    let value = values[position];
      switch(value){
        case 'IN_TREATMENT':
          etapa = `En tratamiento`;
          break;
        case 'SIDE_EFFECTS':
          etapa = `Efectos adversos`;
          break;
        case 'DONT_SEE_RESULT':
          etapa = `No ve resultados`;
          break;
        case 'CHANGED_TREATMENT':
          etapa = `Cambio de tratamiento`;
          break;
        case 'CHANGED_FREQUENCY':
          etapa = `Cambio de frecuencia`;
          break;
        case 'DONT_STARTED':
          etapa = `No empezó`;
          break;
        case 'CHURN':
          etapa = `Baja`;
          break;
        case `Pendiente`:
          etapa = `Pendiente`;
          break;
        case `Leído`:
          etapa = `Leído`;
          break;
        default:
          etapa = value;
          break;
      }
    
   
    return etapa;
  }

}
