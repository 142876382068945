import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gridtagColor',
    standalone: true
})
export class GridTagColorPipe implements PipeTransform {

  transform(values, position: number, ...args: unknown[]): unknown {
    if(values){
      return `${this.genString(values, position)}`;
    } else {
      return null;
    }
  }

  genString(values, position: number): String {
    let classColor: string = '';
    let value = values[position];
      switch(value){
        case 'IN_TREATMENT':
          classColor = `gray-badget`;
          break;
        case 'SIDE_EFFECTS':
          classColor = `gray-badget`;
          break;
        case 'DONT_SEE_RESULT':
          classColor = `gray-badget`;
          break;
        case 'CHANGED_TREATMENT':
          classColor = `gray-badget`;
          break;
        case 'CHANGED_FREQUENCY':
          classColor = `gray-badget`;
          break;
        case 'DONT_STARTED':
          classColor = `gray-badget`;
          break;
        case 'CHURN':
          classColor = `gray-badget`;
          break;
        case 'Pendiente':
          classColor = `orange-badget`;
          break;
        case 'Leído':
          classColor = `gray-badget`;
          break;
        default:
          classColor = value;
          break;
      }
    
   
    return classColor;
  }

}
